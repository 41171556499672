import { useRef } from 'react';
import Typography from '@mui/material/Typography';
import DiscountsImage from '../assets/discounts.png';
import IncentiveImage from '../assets/incentive.png';
import ScientificImage from '../assets/scientific.png';
import EntertainmentImage from '../assets/entertainment.png';
import Accordion from './Accordion';

const Main = () => {
  const discountRef = useRef(null);
  const incentiveRef = useRef(null);
  const environmentRef = useRef(null);

  const openAccordion = (ref, id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: 'smooth'
    });
    ref.current.open();
    
  }
  return (
    <main>
      <h4>Benefits</h4>
      <div className="benefit">
        <h5>Partner Store Discounts</h5>
        <img src={DiscountsImage} alt="Woman holding coffee cup" />
        <p className="benefits">
          <span className="break">Track a number of <button onClick={() => openAccordion(discountRef, 'discount')}>discount campaigns</button> for eco-friendly purchase options offered by our partner stores.</span>
          <span className="break">&nbsp;Save money by participating in each store’s eco-friendly discount programs.</span>
          <span className="break">&nbsp;Each EcoLogical action you take will bust inflation and climate change!</span>
        </p>
        <Accordion
          id="discount"
          ref={discountRef}
          maxWidth={'705px'}
          label="What kinds of discount campaigns are available?"
          className="accordion__discounts">
          <Typography align="left">
            To embrace customers’ demand for sustainability, increasingly more stores are offering discounts for eco-friendly purchase options. EcoLogical provides this discount information to you so that you can receive as many rewards as possible for your good deeds.
          </Typography>
          <Typography align="left">
            Currently, discount campaigns for “bring-your-own-cup (BYOC)” are provided in our app. The example below shows different types of discount campaigns offered by our partner stores for BYOC.
          </Typography>
          <h5 className="partner-store-discounts">
            Types of discount campaigns
          </h5>
          <span className="bold">Price discount for BYOC</span>
          <Typography align="left">e.g.) 10 cents discount per BYOC</Typography>
          <span className="bold">Free deal for BYOC</span>
          <Typography align="left">e.g.) Free Americano for 15 BYOCs</Typography>
          <span className="bold">Loyalty points for BYOC</span>
          <Typography align="left">e.g.) 100 reward points per BYOC</Typography>

          <Typography align="left" sx={{marginTop: '16px'}}>
            At this moment, we only have BYOC-relevant discount campaigns in our app, however, we will add other types of the discount campaigns (e.g., plant-based products, renewable energy, etc.) soon.
          </Typography>
        </Accordion>
      </div>

      <div className="benefit">
        <h5>Direct Incentive From Us</h5>
        <img src={IncentiveImage} alt="Woman holding phone" />
        <p className="benefits">
        <span className="break">On top of all discounts you can receive from our partner stores,</span>
        &nbsp;you can receive <button onClick={() => openAccordion(incentiveRef, 'incentive')}>additional rewards</button> directly from our app for your eco-friendly purchase.
        </p>
        <Accordion
          id="incentive"
          ref={incentiveRef}
          maxWidth={'705px'}
          label="What are the additional rewards from our app?">
          <Typography align="left">
            When you make a sustainable purchase, you can receive additional incentives directly from our app on top of the discounts offered by our partner stores. While some of those incentives are consistently rewarded to you, others are special rewards to make your experience with EcoLogical more fun. The unit of EcoLogical’s reward program is 1 “Eco” where 1000 Eco is typically worth about 1 US dollar.
          </Typography>
        </Accordion>
      </div>

      <div className="benefit">
        <h5>Scientific Environmental Assessment</h5>
        <img src={ScientificImage} alt="Laptop" />
        <p className="benefits">
          <span className="break">We assess the <button onClick={() => openAccordion(environmentRef, 'environment')}>environmental benefits</button> of your eco-friendly purchase using rigorous scientific methods.</span>&nbsp;
          Check how your impacts are improved over time through our user-friendly interfaces.
        </p>
        <Accordion
          id="environment"
          ref={environmentRef}
          maxWidth={'705px'} 
          label="Which environmental benefits are shown in our app?">
          <Typography align="left">
            Currently, EcoLogical shows how much 1) greenhouse gas emissions and 2) waste are reduced per each of your eco-friendly purchases. For example, bringing your own cup typically reduces greenhouse gas emissions by 20 - 30 gCO<sub>2</sub>e (grams of carbon dioxide equivalent) and waste by 10 grams (0.35 US ounce) compared to using a single-use cup.
          </Typography>
        </Accordion>
      </div>

      <div className="benefit">
        <h5>Entertainment</h5>
        <img src={EntertainmentImage} alt="Two people smiling and looking at a phone" />
        <p className="benefits">
          <span className="break">Share your environmental performance or achievement with your friends and family.</span>
          <span className="break">&nbsp;You can also form a team with them to battle with other groups based on their environmental performance.</span>
          <span className="break">&nbsp;Compete how EcoLogical you (or your team) are against your competitors!</span>
        </p>
      </div>
    </main>
  )
}

export default Main;