import Accordion from './Accordion';
import Typography from '@mui/material/Typography';
import { useRef } from 'react';

const Banner = () => {
  const myRef = useRef(null);
  const openAccordion = (ref, id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: 'smooth'
    });
    ref.current.open();
  }

  return (
    <>
    <div className="banner__wrapper">
      <div>
        <h1>EcoLogical</h1>
        <h2>Save our planet to earn more</h2>
      </div>
      {/* <img src={Logo} alt="EcoLogical logo" /> */}
    </div>
    <div className="banner__description">
      <p>
        EcoLogical is a mobile app designed to make your <button onClick={() => openAccordion(myRef, 'ecofriendly')}>eco-friendly purchase</button> easier and more entertaining than ever.
      </p>
      <p>
        <span className="break">With EcoLogical, each of your eco-friendly choices is incentivized in your everyday routine</span> and you can enjoy this with your friends and family.
      </p>
      <Accordion
        id="ecofriendly"
        ref={myRef}
        maxWidth={'589px'}
        label={'What is an eco-friendly purchase?'}>
          <Typography align="left" sx={{marginBottom: '10px'}}>
            There are many alternative purchase options in our daily life that are eco-friendlier than the conventional options. For example, if you bring your own cup (BYOC) instead of using a single-use cup at a coffee shop, you can reduce the environmental impacts such as greenhouse gas emissions and waste from the production and disposal of the single-use cups. Other readily accessible eco-friendly purchase options include drinking non-dairy milk, eating plant-based meat, choosing a renewable electricity plan, and so on. There are many great alternatives that we as consumers can leverage to tackle the environmental problems we are facing.
          </Typography>
          <Typography align="left">
            However, in many cases, it is hard to know each of your eco-friendly purchase’s (e.g., BYOC) environmental benefits, which obscures the huge impact we as consumers can potentially make. EcoLogical uses comprehensive, scientific assessment methods to measure those environmental benefits from your eco-friendly purchase so that you can clearly see the positive changes you’ve brought about to our planet.
            The first kind of eco-friendly purchase to be added in our app will be BYOC. We believe that BYOC is one of the great starting points for many people eager for eco-friendlier consumer choices. Basically, anyone with a will to save our planet can try out BYOCs at no cost or sometimes even with some incentives.
          </Typography>
          <Typography align="left">
            At this moment, EcoLogical app only covers BYOC. However, we will rapidly expand our app to include other types of eco-friendly options (e.g., plant-based products, renewable energy, etc.) to support diverse types of sustainable choices in your daily routine.
          </Typography>
      </Accordion>
    </div>
    </>
  )
}

export default Banner;