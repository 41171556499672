import Header from './components/Header';
import Banner from './components/Banner';
import Main from './components/Main';
import './App.css';

function App() {
  return (
    <>
    <Header></Header>
    <Banner></Banner>
    <Main></Main>
    </>
  );
}

export default App;
