import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Arrow from '../assets/arrow.svg';
import { useState, forwardRef, useImperativeHandle } from 'react';

const EAccordion = forwardRef(({label, children, maxWidth, id}, ref) => {
  const [expanded, setExpanded] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      if (!expanded) {
        setExpanded(true);
      }
    },
  }));

  return (
    <div className="accordion-wrapper" ref={ref} id={id}>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{maxWidth}}>
        <MuiAccordionSummary
          className="accordion-summary"
          expandIcon={<img src={Arrow} alt="" />}
          aria-controls={`panel1bh-content-${id}`}
          id={`panel1bh-header-${id}`}
          sx={{backgroundColor: '#797979', color: '#ffffff', justifyContent: 'center'}}
        >
          <Typography>
            {label}
          </Typography>
        </MuiAccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
})

export default EAccordion;